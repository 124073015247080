var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasImg)?_c('div',{staticClass:"row mb-24"},[_c('div',{staticClass:"col"},[_c('img',{staticClass:"question-img",attrs:{"src":_vm.imgUrl,"alt":""}})])]):_vm._e(),(_vm.content.text)?_c('div',{staticClass:"row mb-24"},[_c('div',{staticClass:"col"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.content.text)}})])]):_vm._e(),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"v-checkbox-media",class:_vm.classes},_vm._l((_vm.content.variant),function(variant){return _c('v-sheet',{key:variant.code,ref:"cardButtons",refInFor:true,staticClass:"v-checkbox-media__item",class:{
        'v-checkbox-media__item_is_image': _vm.hasContentImg(variant),
        'v-checkbox-media__item_is_button': variant.excludingAll || variant.includingAll,
      },style:(_vm.sheetStyles),attrs:{"color":variant.error && !_vm.content.inactive ? 'danger' : null,"cover":_vm.hasContentImg(variant),"value":_vm.hasValue(variant.code),"disabled":_vm.PIC_method_isDisabled(variant.code),"readonly":_vm.content.inactive,"outlined":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleValue(variant.code)}}},[(_vm.hasContentImg(variant))?_c('div',{staticClass:"v-checkbox-media__img",style:({
          backgroundImage: `url(${_vm.getContentImgUrl(variant)})`,
          ..._vm.imageStyles
        })}):_c('div',{staticClass:"v-radio-row"},[_c('div',{staticClass:"v-radio-row__radio"},[_c('v-checkbox',{attrs:{"model-value":_vm.PIC_computed_answers,"value":variant.code}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"v-radio-row__text"},[_c('span',{domProps:{"innerHTML":_vm._s(variant.text)}})])],1)])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }