<template>
  <div>
    <div
      v-if="hasImg"
      class="row mb-24"
    >
      <div class="col">
        <img
          :src="imgUrl"
          class="question-img"
          alt=""
        />
      </div>
    </div>

    <div
      v-if="content.text"
      class="row mb-24"
    >
      <div class="col">
        <span v-html="content.text"/>
      </div>
    </div>

    <div
      :class="classes"
      v-resize="onResize"
      class="v-checkbox-media"
    >
      <v-sheet
        ref="cardButtons"
        v-for="variant in content.variant"
        :key="variant.code"
        :color="variant.error && !content.inactive ? 'danger' : null"
        :cover="hasContentImg(variant)"
        :style="sheetStyles"
        :value="hasValue(variant.code)"
        :disabled="PIC_method_isDisabled(variant.code)"
        :readonly="content.inactive"
        outlined
        rounded
        @click.prevent="toggleValue(variant.code)"
        :class="{
          'v-checkbox-media__item_is_image': hasContentImg(variant),
          'v-checkbox-media__item_is_button': variant.excludingAll || variant.includingAll,
        }"
        class="v-checkbox-media__item"
      >
        <div
          v-if="hasContentImg(variant)"
          class="v-checkbox-media__img"
          :style="{
            backgroundImage: `url(${getContentImgUrl(variant)})`,
            ...imageStyles
          }"
        />

        <div
          v-else
          class="v-radio-row"
        >
          <div class="v-radio-row__radio">
            <v-checkbox
              :model-value="PIC_computed_answers"
              :value="variant.code"
            />
          </div>

          <v-divider vertical/>

          <div class="v-radio-row__text">
            <span v-html="variant.text"/>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { Resize } from '@directives'
import ProcessingImgMixin from '@views/Processing/mixins/ProcessingImgMixin'
import { VCheckbox, VDivider, VSheet } from '@components/base'
import ProcessingInputCheckboxMixin
  from '@views/Processing/ProcessingInput/ProcessingInputCheckbox/ProcessingInputCheckboxMixin'

export default {
  name: 'ProcessingInputCheckboxMedia',

  directives: {
    Resize
  },

  components: {
    VCheckbox,
    VDivider,
    VSheet
  },

  mixins: [
    ProcessingImgMixin,
    ProcessingInputCheckboxMixin
  ],

  data () {
    return {
      cardButtonHeight: 0,
      isOversize: false,
      imgLoadedCount: 1
    }
  },

  computed: {
    sheetStyles () {
      const defaultNumber = {
        xs: 2,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5
      }
      const width = 100 / (this.content[`_number_of_variants_${this.service.breakpoint.current}`] ||
        defaultNumber[this.service.breakpoint.current]) + '%'

      return {
        width: `calc(${width} - 0.5rem)`,
        minHeight: this.cardButtonHeight === 0
          ? null
          : `${this.cardButtonHeight}px`
      }
    },
    imageStyles () {
      return {
        paddingBottom: (this.content[`_height_${this.service.breakpoint.current}`] || '100%')
      }
    },
    classes () {
      return {
        'v-checkbox-media_is_oversize': this.isOversize,
        'v-checkbox-media_is_inactive': this.content.inactive
      }
    }
  },

  methods: {
    /**
     * Действие при изменении размеров окна
     */
    onResize () {
      if (this.isImgButtons) return
      this.setCardButtonHeight()
    },

    /**
     * Подсчёт и установка высоты на кнопку
     * Если текст вылазит за пределы кнопки, isOversize = true
     */
    setCardButtonHeight () {
      if (this.isImgButtons) return
      this.cardButtonHeight = 0
      this.isOversize = false

      this.$nextTick(() => {
        let i = this.$refs.cardButtons.length

        while (i-- && !this.isOversize) {
          const el = this.$refs.cardButtons[i].$el

          if (el.clientHeight > this.cardButtonHeight) {
            this.cardButtonHeight = el.clientHeight + 2
          }

          if (!this.isImgButtons && el.scrollWidth > el.offsetWidth) {
            this.cardButtonHeight = 0
            this.isOversize = true
          }
        }
      })
    },

    /**
     * Проверка наличия ответа в ответах
     * @param value - id ответа
     * @return {boolean} - есть, или нет
     */
    hasValue (value) {
      return this.PIC_computed_answers.includes(value)
    },

    /**
     * Переключить ответ
     * @param code - id ответа
     */
    toggleValue (code) {
      if (this.content.inactive) return

      const oldValues = [...this.PIC_computed_answers]
      const newValues = this.hasValue(code)
        ? this.PIC_computed_answers.filter(answer => answer !== code)
        : [...this.PIC_computed_answers, code]

      this.PIC_method_changed(newValues, oldValues, code)
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.v-checkbox-media {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.25rem;

  &__item {
    width: auto;
    padding: 0;
    margin: .25rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &_is {
    &_inactive {
      .v-checkbox-media__img {
        filter: grayscale(0);
      }
    }

    &_oversize {
      .v-checkbox-media__item {
        flex: 1 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__item {
    width: auto;
    padding: 0;
    margin: .25rem;

    &_is {
      &_image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 .25rem;

        .v-checkbox-media__img {
          filter: grayscale(100%);
        }

        &.v-sheet_is_active {
          .v-checkbox-media__img {
            filter: grayscale(0);
          }
        }
      }

      &_button {
        width: 100% !important;
        min-height: auto !important;
        justify-content: left;
        padding: 0;

        .v-divider {
          display: none;
        }

        .v-radio-row__radio {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
