export default {
  computed: {
    /**
     * Возвращает объект includingAll
     * @return {VariantOption|undefined}
     */
    PEIA_computed_includingAll () {
      return this.content?.variant?.find(variant => variant.includingAll)
    },

    /**
     * Возвращает объект excludingAll
     * @return {VariantOption|undefined}
     */
    PEIA_computed_excludeAll () {
      return this.content?.variant?.find(variant => variant.excludingAll)
    },

    /**
     * Возвращает код ответа с includingAll значением
     * @return {string|undefined}
     */
    PEIA_computed_includeAllCode () {
      return this.PEIA_computed_includingAll?.code
    },

    /**
     * Возвращает код ответа с excludingAll значением
     * @return {string|undefined}
     */
    PEIA_computed_excludeAllCode () {
      return this.PEIA_computed_excludeAll?.code
    }
  }
}
