import { Validatable } from '@/mixins'

export default {
  inject: [
    'sendData',
    'sendDataAjax',
    'service'
  ],

  mixins: [
    Validatable
  ],

  props: {
    content: Object
  },

  computed: {
    PIRM_computed_answer () {
      return this.sendData[this.content.code]?.[0] || ''
    }
  },

  watch: {
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.content.variant.forEach(item => {
        this.$set(item, 'error', this.service.error.isShow)
      })
    }
  },

  mounted () {
    this.checkValidationByCondition(!!this.sendData[this.content.code].length)
  },

  methods: {
    PIRM_methods_setData (newAnswer) {
      this.$set(this.sendData, this.content.code, [newAnswer])
      // проверка на заполнение
      this.checkValidationByCondition(!!newAnswer)

      if (this.content.trigger) {
        this.$set(this.sendDataAjax, 'trigger', this.content.trigger)
        this.$set(this.sendDataAjax, 'value', newAnswer)
        this.service.getContentAjax()
      }
    }
  }
}
