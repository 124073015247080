<template>
  <div>
    <card-button-checkbox
      v-for="variant in content.variant"
      :disabled="PIC_method_isDisabled(variant.code)"
      :error="variant.error"
      :key="variant.code"
      :model-value="PIC_computed_answers"
      :value="variant.code"
      @change="PIC_method_changed"
    >
      <span v-html="variant.text"/>
    </card-button-checkbox>
  </div>
</template>

<script>
import ProcessingInputCheckboxMixin from './ProcessingInputCheckboxMixin'
import { CardButtonCheckbox } from '@components/features'

export default {
  name: 'ProcessingInputCheckbox',

  components: {
    CardButtonCheckbox
  },

  mixins: [ProcessingInputCheckboxMixin]
}
</script>
