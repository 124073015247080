export default {
  computed: {
    hasImg () {
      return this.hasContentImg(this.content)
    },
    imgUrl () {
      return this.getContentImgUrl(this.content)
    },
    isImgButtons () {
      return this.content.variant && this.hasContentImg(this.content.variant[0])
    }
  },

  methods: {
    getContentImgUrl ({ media_file }) {
      return encodeURI(media_file?.source)
    },

    hasContentImg ({ media_file }) {
      return !!media_file?.source
    }
  }
}
