import axios from 'axios'

export default {
  inject: ['service'],

  props: {
    request: {
      type: Object,
      validation: request => request.method && request.url && request.body
    }
  },

  methods: {
    doRequest () {
      if (!this.request) return

      const { method, url, body } = this.request

      axios({
        method,
        url,
        data: body
      })
        .then(
          () => this.service.getContent(),
          (error) => {
            console.error(error)
          }
        )
    }
  }
}
